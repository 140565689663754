<template>
    <div id="tool">
        <div class="icon-wrapper">
            <div v-for="(item, index) in icons" :key="index" :class="['icon', item.class]" @click="handleRoute(item.path)">
                <component :is="item.component"></component>
                <div class="c_name">{{ item.c_name }}</div>
                <div class="e_name">{{ item.e_name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { carbonFactor } from '../../../core/icon';
export default {
    name: "Tool",
    components: {
        carbonFactor,
    },
    data() {
        return {
            icons: [
                { path: '/tool/factor', component: 'carbonFactor', class: 'carbon-factor', c_name:'碳排放因子库',e_name:'Carbon Factors Database'}
            ],
        };
    },
    activated() {
        console.log("activated, tool",);
    },
    methods: {
        handleRoute(path){
            path && this.$router.push({
                path
            });
        }
    },
};
</script>
<style lang="less">
@import "./index.less";
</style>
